@import "~normalize.css";
@import	"core.scss";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');




.header{
    position: relative;
    z-index: 4;
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 0px 2px 5px 0px #00000012;
	
	.container{
		display: flex;
		align-items: center;
	
	}
}


a.logo{
	img{
		display: block;
		max-width: 60px;
	}
}

.video-btn{
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 2em;
	width: 40px;
	height: 40px;
	z-index: 10;
	background: transparent;
	border: 0px;
	outline: none;
	opacity: 0.6;
	transition: width 500ms;
	&:hover{
		opacity: 1;
	}
	&:after{
		content: '';
		position: absolute;
		width: 46px;
		height: 2px;
		background: #fff;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
		transition: width 300ms;
	}
	&.--on{
		&:after{
			width: 0px;
		}

	}
}
.image-box{
	display: block;
    width: 100%;
    height: 700px;
	height: calc(100vh + 20px);

    position: relative;
    margin-top: -110px;
	&__video{
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 1;
	}
	&:before{
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: url(/uploads/images/main/2.jpg) no-repeat center center;
		background-size: cover;
		z-index: 2;
	}

	.container{
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 28px;
		position: relative;
		z-index: 3;
		h1{
			margin-bottom: 13px;
			font-size: 44px;
			max-width: 70%;
			margin: 0px auto 20px;
			text-shadow: 0px 0px 30px #ffffff4a;
			font-weight: 700;
			color: #ffffff;
		}

		.h1-subtext	{
			color: #e2e2e2;
			font-size: 20px;
		}
	}
	
	&::before{
		content: '';
		position: absolute;
		background: linear-gradient(0deg, #00000080, #00000059);
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;

	}
}



.header-contacts{
    margin-left: auto;
    color: #000;
    width: 370px;

	.grid-item{
		margin-bottom: 0;
	}


	.grid-btn{
		align-content: center;
		justify-content: center;
		a{
			color: #fff;
			background: #FFC107;
			line-height: 1em;
			padding: 13px 30px;
			border-radius: 4px;
			cursor: pointer;
			text-decoration: none;
		}
	}

	.grid-numbers{
		align-content: center;
		justify-content: flex-end;
	}


	.phone{
		font-size: 18px;
		font-weight: 600;
	}
	.email{
		font-size: 12px;
		font-weight: 300;
	}
}
.nav{
	display: flex;
	margin-left: 2em;
	a{
		color: #000;
		padding: 10px 10px;
		display: block;
		border-radius: 4px;
		font-size: 16px;
		text-decoration: none;
		font-weight: 600;
		&:hover{
			color: var(--color-main);
		}

	}
	&-item{
		&:last-child{
			

		}
		position: relative;
		&__childs{
			top: 100%;
			left: 0;
			/* z-index: 5; */
			position: absolute;
			display: block;
			opacity: 0;
			visibility: hidden;
			padding-top: 10px;
			transform: translateY(-10px);
			transition: 100ms;
			&::before {
				content: '';
				position: absolute;
				left: 1em;
				top: 0;
				width: 0;
				height: 0;
				border-left: 7px solid transparent;
				border-right: 7px solid transparent;
				border-bottom: 10px solid #fff;
			}

			&-list{
				list-style-type: none;
				min-width: 100%;
				background: #fff;
				padding: 10px 15px;
				box-shadow: 0px 4px 20px rgba(19, 23, 39, 0.15);
				border-radius: 8px;
				min-width: 200px;

				a {
					display: block;
					font-size: 16px;
					padding: 8px 0;
				}
			}

		}

		&:hover{
			.nav-item__childs{
				opacity: 1;
				visibility: visible;
				transform: translateY(0px);
			}

		}
	}
}


.home-page{

	.nav{
		a{
			color: #fff;

		}

		&-item__childs
		{
			a{
				color: #000;

			}
		}
	}
	.header{
		background: transparent;

		border-bottom: 0px solid #e0e0e0;
		box-shadow:none;
		
	}

	a.logo{
		img{
			display: block;
			max-width: 70px;
		}
	}
	

	
	.main{
		.container{
			padding-top: 80px;
			padding-bottom: 60px;
		}
	}
	.header-contacts{
		color: #fff;
	}
}


.products{
	.product__item{
		display: block;
		width: 100%;
		a{
			color: #000;
		}

		.product__image{
			position: relative;
			width: 100%;
			display: block;
			margin-bottom: 1em;
			display: block;
			text-align: center;
			text-decoration: none;

			img{
				display: block;
				width: 100%;
				height: 250px;
				object-fit: cover;
				margin-bottom: 20px;
				
			}

			h3{
				font-size: 24px;
			}
		}


		.product__text{
			font-size: 14px;
			padding: 0 10px;
			margin-bottom: 10px;
			a{
				text-decoration: underline;
				margin-top: 10px;
				display: inline-flex
			}
		}
		.btn{

		}
	}
}

.pluses{
	--grid-space:60px;
	img{
		border-radius: 5px;
	}

	ol{
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		li{
			width: 100%;
			position: relative;
			padding-left: 50px;
			box-sizing: border-box;
			padding-right: 10px;
			margin-bottom: 26px;
			font-size: 19px;
			min-height: 34px;
			line-height: 1.3em;

			&::before {
				content: attr(data-number);
				display: block;
				left: 0;
				top: 3px;
				width: 34px;
				height: 34px;
				background: var(--color-main);
				text-align: center;
				line-height: 34px;
				font-size: 16px;
				border-radius: 3px;
				position: absolute;
				font-weight: 600;
				color: #fff;
			}
		}
	}
}

ol{
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	li{
		width: 100%;
		position: relative;
		padding-left: 50px;
		box-sizing: border-box;
		padding-right: 10px;
		margin-bottom: 1em;
		font-size: 16px;
		font-weight: 500;

		&::before {
			content: attr(data-number);
			display: block;
			left: 0;
			top: 3px;
			width: 34px;
			height: 34px;
			background: var(--color-main);
			text-align: center;
			line-height: 34px;
			font-size: 16px;
			border-radius: 3px;
			position: absolute;
			font-weight: 600;
			color: #fff;
		}
	}
}


.reviews{
	.container{
		padding-top: 80px;
		padding-bottom: 80px;
		@include size(s){
			padding-top: 40px;
			padding-bottom: 40px;
			.h2{
				font-size: 30px;
			}
		}
	}
}

.review{
	flex-wrap: wrap;
	padding-top: 80px;
	padding-bottom: 80px;
	@include size(s){
		padding-top: 20px;
	}

	.review-image{
		img{
			display: block;
			width: 200px;
			height: 200px;
			border-radius: 50%;
			margin: 0px auto 0em;
			object-fit: cover;
		}
	}
	.review-text{
		font-size: 20px;
		line-height: 1.55;
		font-weight: 300;
		margin-top: 40px;
		margin-bottom: 32px;
	}
	.review-name{
		margin-top: 8px;
		margin-bottom: 5px;
		font-size: 22px;
		line-height: 1.35;
		font-weight: 600;
	}
	.review-date{
		margin-top: 0;
		margin-bottom: 10px;
		font-size: 14px;
		line-height: 1.55;
		font-weight: 300;
	}





}
.swiper-button-prev.swiper-button-white,
.swiper-button-next.swiper-button-white {
	--swiper-navigation-color: #bbbbbb!important;
}
.reviews{
	background: whitesmoke;
	.slider__contolls{
		.swiper-pagination{
			position: absolute;
			text-align: center;
			transition: 300ms opacity;
			transform: translate3d(0, 0, 0);
			z-index: 10;
			color: #000;
			bottom: 1em;
			left: 50%;
			transform: translateX(-50%);
			font-weight: 600;
		}
	}

}


.partners{
	.partners-list{
		max-width: 80%;
		margin: 0px auto;
		a{
			img{
				display: block;
			}
		}
	}
}

.color-red{
	color: red;
}
.call-me{

	position: relative;
	&::before{
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background: var(--color-main);
	}

}

.footer{
    border-top: 2px solid #333333;
    background: #363636;

	.container{
		padding-top: 20px;
		padding-bottom: 20px;
		&:last-child{
			border-top: 1px solid #2d2d2d;
		}	
		small{
			color: #8c8c8c;
		}
	}

	.footer__table {
		a{
			display: block;
			color: #fff;
		}
		b{
			color: #fff;
			font-weight: 700;
			margin-bottom: 1em;
			display: block;
		}
		.table-cell{
			padding-right: 50px;
		}
	}
	.footer__nav {
		color: #c4c4c4;
		font-weight: 400;
	}


	.footer__nav a {
		color: #c4c4c4;
		font-weight: 400;
		display: block;
		margin-bottom: 5px;
	}

	.footer__nav .footer__li-icon {
		display: block;
		margin-bottom: 10px;
		line-height: 1.3em;
		position: relative;
		padding-left: 25px;
	}

	.footer__nav .footer__li-icon::before {
		content: '';
		position: absolute;
		width: 18px;
		height: 18px;
		display: block;
		left: 0;
		top: -3px;
		background-position: center;
		background-size: cover;
	}

	.footer__nav .footer__li-icon.--icon-email::before {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 39.5 39.5'%3E%3Cpath d='M38.3 4.8c0.6 0 1.1 0.5 1.1 1.1v27.6c0 0.6-0.5 1.1-1.1 1.1h-0.3H1.4h-0.3C0.5 34.6 0 34.1 0 33.5V6c0-0.6 0.5-1.1 1.1-1.1h0.3 0.3 0.6c11.8 0 23.7 0 35.5 0h0.3H38.3zM19.7 20.3l8.4-7.3 6.9-6h0H34.5c-9.6 0-19.3 0-29 0h-0.5 -0.5H4.4l6.9 6L19.7 20.3zM29.6 14.7l-9.2 7.9c-0.4 0.4-1 0.4-1.5 0l-9.2-8L2.2 8.2v23.2l7.6-6.6 4.3-3.7c0.5-0.4 1.2-0.4 1.6 0.1 0.4 0.5 0.3 1.2-0.1 1.6l-4.3 3.7 -6.9 6h30.7l-6.9-6 -4.3-3.7c-0.5-0.4-0.5-1.1-0.1-1.6 0.4-0.5 1.1-0.5 1.6-0.1l4.3 3.7 7.6 6.6V8.2L29.6 14.7z' /%3E%3C/svg%3E");
		top: 0px;
	}

	.footer__nav .footer__li-icon.--icon-address::before {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 512 512'%3E%3Cpath d='M426 89C386.9 32.5 324.9 0 256 0S125.1 32.5 86.1 89c-38.9 56.3-47.9 127.5-24.1 190.5 6.4 17.2 16.5 33.9 30 49.5l150.4 176.6c3.4 4 8.4 6.3 13.6 6.3 5.2 0 10.2-2.3 13.6-6.3l150.3-176.6c13.6-15.7 23.7-32.4 30-49.5C473.8 216.6 464.8 145.3 426 89zM416.5 267.1c-4.9 13.2-12.8 26.2-23.6 38.7 0 0-0.1 0.1-0.1 0.1L256 466.5 119.2 305.8c-10.8-12.5-18.7-25.5-23.7-38.8 -19.7-52.1-12.2-111 20-157.6 32.3-46.8 83.5-73.6 140.5-73.6 57 0 108.2 26.8 140.5 73.6C428.7 156 436.2 214.9 416.5 267.1z'/%3E%3Cpath d='M256 106.2c-55.3 0-100.3 45-100.3 100.3s45 100.3 100.3 100.3 100.3-45 100.3-100.3C356.3 151.2 311.3 106.2 256 106.2zM256 270.9c-35.5 0-64.4-28.9-64.4-64.4 0-35.5 28.9-64.4 64.4-64.4 35.5 0 64.4 28.9 64.4 64.4S291.5 270.9 256 270.9z'/%3E%3C/svg%3E");
	}

	.footer__nav .footer__li-icon.--icon-phone::before {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 482.6 482.6'%3E%3Cpath d='M98.3 320.8c47.6 56.9 104.9 101.7 170.3 133.4 24.9 11.8 58.2 25.8 95.3 28.2 2.3 0.1 4.5 0.2 6.8 0.2 24.9 0 44.9-8.6 61.2-26.3 0.1-0.1 0.3-0.3 0.4-0.5 5.8-7 12.4-13.3 19.3-20 4.7-4.5 9.5-9.2 14.1-14 21.3-22.2 21.3-50.4-0.2-71.9l-60.1-60.1c-10.2-10.6-22.4-16.2-35.2-16.2 -12.8 0-25.1 5.6-35.6 16.1l-35.8 35.8c-3.3-1.9-6.7-3.6-9.9-5.2 -4-2-7.7-3.9-11-6 -32.6-20.7-62.2-47.7-90.5-82.4 -14.3-18.1-23.9-33.3-30.6-48.8 9.4-8.5 18.2-17.4 26.7-26.1 3-3.1 6.1-6.2 9.2-9.3 10.8-10.8 16.6-23.3 16.6-36s-5.7-25.2-16.6-36l-29.8-29.8c-3.5-3.5-6.8-6.9-10.2-10.4 -6.6-6.8-13.5-13.8-20.3-20.1 -10.3-10.1-22.4-15.4-35.2-15.4 -12.7 0-24.9 5.3-35.6 15.5l-37.4 37.4c-13.6 13.6-21.3 30.1-22.9 49.2 -1.9 23.9 2.5 49.3 13.9 80C32.7 229.6 59.1 273.7 98.3 320.8zM25.7 104.2c1.2-13.3 6.3-24.4 15.9-34l37.2-37.2c5.8-5.6 12.2-8.5 18.4-8.5 6.1 0 12.3 2.9 18 8.7 6.7 6.2 13 12.7 19.8 19.6 3.4 3.5 6.9 7 10.4 10.6l29.8 29.8c6.2 6.2 9.4 12.5 9.4 18.7s-3.2 12.5-9.4 18.7c-3.1 3.1-6.2 6.3-9.3 9.4 -9.3 9.4-18 18.3-27.6 26.8 -0.2 0.2-0.3 0.3-0.5 0.5 -8.3 8.3-7 16.2-5 22.2 0.1 0.3 0.2 0.5 0.3 0.8 7.7 18.5 18.4 36.1 35.1 57.1 30 37 61.6 65.7 96.4 87.8 4.3 2.8 8.9 5 13.2 7.2 4 2 7.7 3.9 11 6 0.4 0.2 0.7 0.4 1.1 0.6 3.3 1.7 6.5 2.5 9.7 2.5 8 0 13.2-5.1 14.9-6.8l37.4-37.4c5.8-5.8 12.1-8.9 18.3-8.9 7.6 0 13.8 4.7 17.7 8.9l60.3 60.2c12 12 11.9 25-0.3 37.7 -4.2 4.5-8.6 8.8-13.3 13.3 -7 6.8-14.3 13.8-20.9 21.7 -11.5 12.4-25.2 18.2-42.9 18.2 -1.7 0-3.5-0.1-5.2-0.2 -32.8-2.1-63.3-14.9-86.2-25.8 -62.2-30.1-116.8-72.8-162.1-127 -37.3-44.9-62.4-86.7-79-131.5C28 146.4 24.1 124.3 25.7 104.2z'/%3E%3C/svg%3E");
	}

	.footer__nav .footer__li-icon span {
		display: inline-block;
		border-bottom: 1px solid;
	}
}

.subtitles{
	margin-top: 2em;
	h1{
		font-size: 26px;
	}
}

.price-table{
    max-width: 700px;
    text-align: center;
    border-collapse: collapse;
    border: 1px solid transparent;
    margin: 0px auto;

	.table-head, th{
		font-weight: 600;
	}
	.table-cell, td{

		border: 1px solid #ddd;
		padding: 10px 5px;
	}
}

.notice{



}

a{
	color: #000;
	text-decoration: underline;
}

.contact__list {
    font-size: 19px;
    line-height: 1.3em;
}

.contact__list .contact__item {
    display: block;
    margin-bottom: 50px;
}

.contact__list .contact__item.--address .contact__title::before {
    background-image: url(/uploads/images/svg/place.svg);
}

.contact__list .contact__item.--phone a {
    text-decoration: none;
}

.contact__list .contact__item.--phone .contact__title::before {
    background-image: url(/uploads/images/svg/telephone.svg);
}

.contact__list .contact__item.--email .contact__title::before {
    background-image: url(/uploads/images/svg/envelope.svg);
}

.contact__list .contact__title {
    display: block;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px;
    color: var(--color-font);
    position: relative;
    padding-left: 30px;
}

.contact__list .contact__title::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    bottom: 1px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.contact__list .contact__value a {
    display: block;
}



.gallery{
	&__list{

	}
	&__item{
		width: 100%;
		height: 200px;
		border-radius: 5px;
		overflow: hidden;
		transition: 200ms;
		-webkit-filter: brightness(1.1) contrast(1.1);
		filter: brightness(1.1) contrast(1.1);
		box-shadow: 0px 0px 0px 1px #000;
		&:hover{
			opacity: 0.8;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		@include size(s){
			height: 140px;
		}
	}
}


.lists {
	&__item{
		display: block;
		width: 100%;
		text-align: center;
		text-decoration: none;
		font-weight: 600;
		box-sizing: border-box;
		height: 100%;
		padding: 1em;
		box-sizing: border-box;
		border-radius: 5px;
		border: 1px solid #ddd;
		object-position: bottom;

		img{
			display: block;
			width: 150px;
			height: 150px;
			object-fit: none;
			margin: 0 auto 1em;
			border: 1px solid #ddd;
			object-position: -160px calc(50% - -65px);
		}
		div{
			display: block;
		}
	}
}

.truba-ul{
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		font-weight: 400;
		position: relative;
		margin-bottom: 1.5em;
		b{
			display: block;
			margin-bottom: 10px;
		}

		li {

			position: relative;
			margin-bottom: 10px;
			padding-left: 1em;

			&:before {
				content: '';
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: var(--color-main);
				position: absolute;
				left: 0;
				top: calc(0.5em - 2px);
			}
		}
	}
}
.truba-gallery{
	text-align: center;
	a{
		border: 1px solid #ddd;
		width: 100%;
		padding: 1em;
		box-sizing: border-box;
		border-radius: 5px;
		margin: 0 auto;
		text-decoration: none;
		font-weight: 700;
		img{
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			object-fit: contain;
			max-height: 300px;
		}

		span{
			display: block;
		}

		&:hover{
			border-color: #000;
		}
	}

}