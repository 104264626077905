@mixin size($breakpoint) {
	@if map-has-key($windowSizes, $breakpoint) {
		@media screen and (max-width: #{map-get($windowSizes, $breakpoint)}) {
			@content;
		}
	}
	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
	}
}

@mixin screen($size) {
	@media screen and (max-width: #{$size}px) {
		@content;
	}
}


$color: (
    a: #212121,
    a-hover:#FF5722,
    main:#ffc107, // Основной цвет
    orange:#FF5722,
    black: #000,
    font: #000, // Кнопки меню
    svg: #ffc107, // Кнопки меню
);
$color-varibles: (
    orange:#FF5722,
    blue: #2f98ff, // Кнопки меню
);

$font: (
    family: "'Montserrat', sans-serif",
    size:16px,
);

$font-size-tags: (
    body:var(--font-size),
    p:1rem,
    h1: 33px,
    h2: 27px,
    h3: 24px,
    h4: 19px,
    h5: 16px,
);

$params: (
    text-shadow:  0 2px 2px rgba(0,0,0,.5),
    container-min-width:375px,
    container-max-width:1150px,
    container-margin:0px auto,
    container-padding:1em,
    border-radius: 5px,
    animation:200ms,
);


$grid-length: 10, 8, 7, 6, 5, 4, 3, 2, 1;
$grid-item-margin-side:1em;
$grid-item-margin-side-negative:-1em;
$grid-item-margin-bottom:1em;

$mgb-mgt-length:0,1,2,3,4,5,6;
$text-rows-on-line:0,1,2,3,4,5;

$container-sizes: 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;


$windowSizes: (
    l:1300px,
    m:1024px,
    s:808px,
);

:root {
    // each item in color map
    @each $name, $c in $color {
      --color-#{$name}: #{$c};
    }
    @each $name, $value in $font {
      --font-#{$name}:  #{$value};
    }
    @each $name, $value in $font-size-tags {
        --font-size-#{$name}:  #{$value};
    }

    @each $name, $value in $params {
        --#{$name}:  #{$value};
    }

    --grid-item-margin-side:#{$grid-item-margin-side};
    --grid-item-margin-side-negative:#{$grid-item-margin-side-negative};
    --grid-item-margin-bottom:#{$grid-item-margin-bottom};
}





.swiper-container {
    width: 100%;
    height: 100%;


    .swiper-slide {
        text-align: center;
        font-size: 18px;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }


    .swiper-pagination{

        &.swiper-pagination-bullets{

        }

        .swiper-pagination-bullet{
            width: 14px;
            height: 14px;
            box-sizing: border-box;
            background: transparent;
            border: 2px solid #fff;
            opacity: 1;
            &.swiper-pagination-bullet-active{
                background: var(--color-main);
                border-color:  var(--color-main);
            }
        }

        &.swiper-pagination-fraction{
            background: rgba(0, 0, 0, 0.8);
            color: #fff;
            left: 50%;
            right: auto;
            width: auto;
            transform: translate(-50%, -50%);
            font-size: 14px;
            padding: 5px;
            line-height: 1em;
            padding: 4px 5px 6px;
            border-radius: 4px;
        }
    }

    .swiper-button-next,
    .swiper-button-prev{

    }

    .swiper-button-next{

    }

    .swiper-button-prev{

    }

}

@keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

[v-cloak], .v-cloak {
    display: block;
    position: relative;


    &:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%;
      border: 2px solid #ccc;
      border-top-color: #333;
      animation: spinner .6s linear infinite;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    & > div {
      opacity: 0.2;
    }
  }

*{
    margin: 0;
    padding: 0;
}

html,body{
    height: 100%;
    width: 100%;
}




body{
    line-height: 1.4em;
    font-family: #{map-get($font,'family')};
    font-weight: 400;
    background: #{map-get($color,'bg')};
    color: #{map-get($color,'font')};
    font-size: var(--font-size);
}

p{
    line-height: 1.5em;
    margin-top: 0;
	margin-bottom:1em;
	color:var(--color-font);
	word-wrap: break-word;
}
b{
    font-weight: bold;
    color:#333333;
}
.nowrap{
	white-space: nowrap;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    overflow: hidden;
}
a,
a:visited{
    text-decoration: none;


}

button, input, select, textarea{
    font-family: #{map-get($font,'family')};
	font-weight: 400;
}
button, select{
    cursor: pointer;
}
img:not(.prevent){max-width:100%;}



@each $hSize in (1,2,3,5) {
    h#{$hSize}, .h#{$hSize}{
        display: block;
        margin-bottom: 0;
        font-weight: 600;
        margin-top: 0;
        padding-top: 0;
        color:var(--color-black);
        line-height: 1.2em;
        span{
            font-weight: bold;
        }
    }
}

@each $tagName, $size in $font-size-tags {
    #{$tagName}{font-size:#{$size}}
    .#{$tagName}{
        font-size:#{$size};
    }
}

h5{
    font-weight: 500;
}

.clear{	clear:both;}

.table{display: table;}
.table-cell{display: table-cell;vertical-align: middle;box-sizing: border-box;}
.table-row{display: table-row; vertical-align: middle; box-sizing: border-box;}
.table-cell.right-cell{text-align:right;}

.cf, .grid, .fl {zoom: 1;}
.cf:before,	.grid:before, .cf:after, .grid:after, .fl:after, .fl:before {content: "";display: table;}
.cf:after, .grid:after, .fl:after {  clear: both;}
.fl{display:block;}
.fl-left{float:left; box-sizing: border-box;}
.fl-right{float:right; box-sizing: border-box;}

$alAlign: (
    'al-left': "left!important",
    'al-center': "center!important",
    'al-right': "right!important",
);
$vlAlign: (
    'vl-top': "top!important",
    'vl-middle': "middle!important",
    'vl-bottom': "bottom!important",
    'vl-inline': "baseline!important",
);
@each $class, $val in $alAlign {
    .#{$class}{text-align:#{$val};}
}
@each $class, $val in $vlAlign {
    .#{$class}{vertical-align:#{$val};}
}

ul{
    margin-top: 0;
    li{

    }
}

.upper{text-transform: uppercase;}
.noselect{-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
.flex-box{display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-flow: row wrap;-ms-flex-flow: row wrap;-webkit-flex-direction: row;flex-direction: row;}
.centered{margin-left: auto;margin-right: auto;}
.animated{transition: #{map-get($params,'animation')};}
.db{display: block;}
.dtc{display: table-cell;}
.dib{display: inline-block;}
.dn{display: none;}
.hidden, .vh{visibility: hidden;opacity: 0;width: 0;height: 0;position: absolute;overflow: hidden;}
.visible, .vs{
    visibility: hidden;
    opacity: 1;
    width: auto;
    height: auto;
    position: relative;
    overflow: visible;
}
.hide{opacity: 0;visibility: hidden;}
.show{opacity: 1;visibility: visible;}

.box{
    display: inline-block;
    box-sizing: border-box;
    padding: 2em;
    position: relative;
    min-height: 200px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(19, 23, 39, 0.05);
    border-radius: 10px;

}

.container{

    min-width:  #{map-get($params,'container-min-width')};
    max-width:  #{map-get($params,'container-max-width')};
    margin:     #{map-get($params,'container-margin')};
	padding:    #{map-get($params,'container-padding')};

	min-width:  var(--container-min-width);
    max-width:  var(--container-max-width);
    margin:     var(--container-margin);
	padding:    var(--container-padding);
	box-sizing: border-box;
    position:   relative;
    z-index:    1;


}

.w33{
    width:calc(100% / 3);
}
@each $size in $container-sizes {
    .w#{$size}{width:#{$size + '%'}}
    .h#{$size}{height:#{$size + '%'}}

}

@each $number in $mgb-mgt-length {
    .mgb#{$number}{margin-bottom:#{$number + 'em'};--grid-item-margin-bottom: #{$number + 'em'};}
    .mgt#{$number}{margin-top:#{$number + 'em'}}
}

/* Отступы */

@each $className, $ident in (
    '0': 0,
    '10': '1em 0 1em 0',
    '01': '0 1em 0 1em',
    '1': '1em 1em 1em 1em',
    '20': '2em 0 2em 0',
    '02': '0 2em 0 2em',
    '2': '2em 2em 2em 2em',
    '30': '3em 0 3em 0',
    '03': '0 3em 0 3em',
    '3': '3em 3em 3em 3em',
) {

    .p#{$className}{padding:#{$ident}}
    .m#{$className}{margin:#{$ident}}


    @each $SizeName, $WindowSize in $windowSizes {
        @media only screen and (max-width: $WindowSize) {
            .#{$SizeName}-p#{$className}{padding:#{$ident}!important;}
            .#{$SizeName}-m#{$className}{margin:#{$ident}!important;}
        }
    }

}


.of-lines{
    --size:2;
    display: -webkit-box;
    -webkit-line-clamp: var(--size);
    -webkit-box-orient: vertical;
    overflow: hidden;
}






.scroll-x{
    width: auto;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    position: relative;



    &::-webkit-scrollbar {
        height: 10px;
        cursor: pointer;
        background-color: var(--color-bg-light);
        border-radius: 5px;
    }


    &::-webkit-scrollbar-thumb {
        height: 10px;
        background-color:  var(--color-font);
        border-radius: 5px;
        cursor: pointer;
    }

    .scroll-item{
        display: inline-block;
        &:last-child{
            margin-right: 0px!important;
        }
    }
}

.inline-items{
    display: block!important;
    margin: 0!important;

    .grid-item,
    .inline-item{
        min-width: auto;
        padding: 0;
        margin-left: 0;
        margin-right: 8px;
        display: inline-block;
        width: auto;
        margin-bottom: 5px;
    }
}


@each $SizeName, $WindowSize in $windowSizes {
    @media only screen and (max-width: $WindowSize) {

        .#{$SizeName}-inline-items{

            display: block!important;
            margin: 0!important;

            .grid-item,
            .inline-item{
                min-width: auto;
                padding: 0;
                margin-left: 0;
                margin-right: 10px;
                display: inline-block;
                width: auto;
            }
        }
        .scroll-x,
        .#{$SizeName}-scroll-x{
            width: auto;
            overflow-x: auto;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch;
            position: relative;
            display: flex;
            flex-wrap: nowrap!important;
            margin-left: 0!important;
            margin-right: -1em!important;

            &::-webkit-scrollbar {
                height: 8px;
                cursor: pointer;
                background-color: var(--color-bg-light);
                border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb {
                height: 8px;
                background-color:  var(--color-font);
                border-radius: 5px;
                cursor: pointer;
            }

            .grid-item,
            .scroll-item{
                min-width: 200px;
                padding: 0;
                margin-right: var(--grid-space);
            }
        }
    }

}

@each $line in $text-rows-on-line {
    .of-#{$line}-lines{
        --size:#{$line};
    }
}

.grid-item{
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    position: relative;
    width:calc(100% / var(--grid-size));
    align-content: start;
    padding:0 calc(var(--grid-space) / 2);
    margin-bottom: 1em;

}




@each $grid in $grid-length {
    .grid-#{$grid}{
        --grid-space:1em;
        width: auto;


        margin: 0 calc(calc(var(--grid-space) / 2) - calc(calc(var(--grid-space) / 2) * 2));

        @if $grid == 1 {
            margin-left: 0;
            margin-right: 0;
        }

        display: flex;
        flex-wrap: wrap;
    }

}

.grid-1{
    .grid-item{
        width: 100%;
    }
}

@each $grid in $grid-length {
    .grid-#{$grid}{
        --grid-size:#{$grid};
    }

}


@each $SizeName, $WindowSize in $windowSizes {




    @media only screen and (max-width: $WindowSize) {



        @each $grid in $grid-length {
            .#{$SizeName}-grid-#{$grid} > .grid-item{
                --grid:#{$grid};
                width:calc(100% / #{$grid} );
            }
        }
        .#{$SizeName}-grid-1 > .grid-item{
            width: 100%;;
        }

        @each $class, $val in $alAlign {
            .#{$SizeName}-#{$class}{text-align:#{$val};}
        }
        @each $class, $val in $vlAlign {
            .#{$SizeName}-#{$class}{vertical-align:#{$val};}
        }


        @each $size in $container-sizes {
            .grid-item.m-w#{$size}{width:#{$size + '%'}}
            .#{$SizeName}-w#{$size}{width:#{$size + '%'}}
            .#{$SizeName}-h#{$size}{height:#{$size + '%'}}
        }


        .#{$SizeName}-dn{display: none!important;}
        .#{$SizeName}-db{display: block!important;}
        .#{$SizeName}-dib{display: inline-block!important;}
        .#{$SizeName}-al-left{text-align:left!important;}
        .#{$SizeName}-al-center{text-align:center!important;}
        .#{$SizeName}-al-right{text-align:right!important;}
        .#{$SizeName}-vl-top{vertical-align:top!important;}
        .#{$SizeName}-vl-middle{vertical-align:middle!important;}
        .#{$SizeName}-vl-bottom{vertical-align:bottom!important;}
        .#{$SizeName}-hidden{
            visibility: hidden;
            opacity: 0;
            width: 0;
            height: 0;
            position: absolute;
            overflow: hidden;
        }

        .#{$SizeName}-visible{
            visibility: hidden;
            opacity: 1;
            width: auto;
            height: auto;
            position: relative;
            overflow: visible;
        }

        @each $number in $mgb-mgt-length {
            .#{$SizeName}-mgb#{$number}{margin-bottom:#{$number + 'em'}!important}
            .#{$SizeName}-mgt#{$number}{margin-top:#{$number + 'em'}!important}
        }

        @each $line in $text-rows-on-line {
            .#{$SizeName}-of-#{$line}-lines{
                --size:#{$line};
            }
        }
    }



}

.font-l{
    font-size: 1.1em;
}



input[type=text],
input[type=tel],
input[type=email],
input[type=password]
input[type=date]
{
    display: inline-flex;
    padding: 15px 1em;
    -webkit-appearance: none;
    line-height: 1em;
    border: 0px;
}

.btn,
a.btn {

    display: inline-flex;
    align-content: center;
    align-items: center;
    position: relative;
    margin: 0;
    padding: 0;
    cursor: pointer;
    outline: 0;
    user-select: none;
    border-radius: 5px;
    text-align: center;
    white-space: nowrap;
    transition-duration: .2s;
    transition-property: background,border-color,color,width;
    transition-timing-function: ease;
    position: relative;
    justify-content: center;
    color: rgba(11,31,53,.95);
    background: transparent;
    border: 1px solid rgba(0,0,0,0.14);

    padding: 10px 20px;


    &:hover{
        border: 1px solid rgba(0,0,0,0.3)
    }



    &.btn__link{
        padding: 0;
        border-radius: 0;
        border: 0px solid;
        text-decoration: underline;
    }

    &.--main{
        background:var(--color-main);
        border-color:var(--color-main);;
        color: #fff;

        &:hover{

            filter: contrast(1.1);
        }
    }


    &:active{

    }

    &.--orange{
        background: linear-gradient(45deg, #ec8f06, #FF9800);
    }


	&.--is-loading{
		position: relative;
		color: transparent;

		&:before {
			content: '';
			box-sizing: border-box;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 24px;
			height: 24px;
			margin-top: -12px;
			margin-left: -12px;
			border-radius: 50%;
			border: 2px solid #ffffff24;
			border-top-color: #fff;
			animation: spinner .6s linear infinite;
			text-indent: 100%;
			white-space: nowrap;
			overflow: hidden;
		}
	}




    @each $name, $c in $color-varibles {
        &.--color-#{$name}{
            --color: #{$c};
        }

    }





}

.ui-dropdown{
    position: relative;
    .ui-dropdown__container{
        visibility: hidden;
        opacity: 0;
        position: absolute;
        box-shadow: 1px 2px 4px 0 rgba(0,0,0,.25);
        transition: all .2s ease-in-out;
        top: 100%;
        background: #fff;
        padding: 10px;
        font-weight: 400;

    }
    &.ui-dropdown--open{
        .ui-dropdown__container{

            visibility: visible;
            opacity: 1;
        }
    }
}


input[type=text],
input[type=tel],
input[type=email],
input[type=password]
input[type=date],
textarea{
    border: 0px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: #{map-get($font,'regular')};
    padding: 0 1em;

}

.font_segoe_ui{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.icon{
    display: flex;
    line-height: 1.3em;
    text-decoration: none;
    align-items: center;
    color: rgba(11,31,53,.95);
    background: transparent;
    outline: none;
    border: 0;


     font-weight: 400;
    font-size: 16px;
    position: relative;
    cursor: pointer;
    border: none;
    background: none;
    margin: 0;
    outline: 0;
    height: 40px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-width: 100%;

    span{
        margin-top: -1px;
    }


    > * {
        margin-left: 5px;

        &:nth-child(1){
            margin-left: 0px;
        }
    }

    &.--s{
        font-size: 13px;
    }


    @each $SizeName, $WindowSize in $windowSizes {

        @media only screen and (max-width: $WindowSize) {
            &.#{$SizeName}-l{
                &::before{
                    content: '';
                    width: 24px;
                    height: 24px;
                }
            }
            &.#{$SizeName}-xl{
                &::before{
                    content: '';
                    width: 28px;
                    height: 28px;
                }
            }
            &.#{$SizeName}-mini{
                &::before{
                    margin-right: 0;
                }
                span{
                    display: none;
                }
                box-sizing: border-box;
                padding: 10px;
                border-radius: 5px;
            }
        }
    }


    &.--decorated{
        transition: border-color .1s ease-out;

        span{
            border-bottom-width: 1px;
            border-bottom-style: dashed;
            border-color: rgba(11,31,53,.3);
        }
    }

}
[data-counter]{
    position: relative;
    &::after{
        content: attr(data-counter);

        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: 24px;
        height: 15px;
        border-radius: 82px;
        background-color: #f03226;
        position: absolute;
        top: 5px;
        right: -16px;

        display: inline-block;
        text-align: center;
        font-size: 10px;
        color: #fff;
        font-weight: 400;
        line-height: 1.5em
    }
}
.svg-container{
    --size:2em;
    height: var(--size);
    line-height: var(--size);
    width: var(--size);
    display: inline-flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    &.-shield{
        background-image: url(/assets/img/svg/shield.svg);
    }
}


.tooltip{
    --color-border: #2d2d2d;
    position: fixed;
    max-width: 180px;
    font-size: .85em;
    line-height: 1.3em;
    background: #171717;
    padding: 8px 12px;
    opacity: 0;
    color: #fff;
    visibility: hidden;
    transition: .3s ease-in-out opacity;
    text-align: center;
    --arrow-size: 14px;
    z-index: 11;
    box-shadow: 0 2px 10px 0 rgba(140,140,140,.16);
    border-radius: 3px;

	&.show, &.fixed_show{
		opacity: 1;
		visibility: visible;
    }
    /*

	.tooltip_title{

	}
	.tooltip_container{

	}
	.tooltip_footer{

    }
    */

	&::before{
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		border-left: calc(var(--arrow-size) / 2) solid transparent;
		border-right: calc(var(--arrow-size) / 2) solid transparent;
		border-bottom: calc(var(--arrow-size) / 2) solid var(--color-border);
		left:50%;
		bottom: 100%;
		transform:translateX(calc(var(--arrow-size) / -2)) ;
	}
}

.assept{
    font-size: 14px;
    cursor: pointer;
    line-height: 1.3;

    font-weight: 500;
    @extend .noselect;
    input{
        display: none;
    }
    div{

        color: #000;
        position: relative;
        padding-left: 2em;

        &::before{
            content: '';
            border: 1px solid #9d9d9d;
            width: 1em;
            height: 1em;
            background: #fff;
            position: absolute;
            top: 3px;
            left: 0%;
            border-radius: 2px;
            background-size: calc(1em - 5px);
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    input[type=checkbox]:checked + div{
        &::before{
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'%3E%3Cpath fill='%23d12344' d='m0.3 14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4 0l0.1 0.1 5.5 5.9c0.2 0.2 0.5 0.2 0.7 0l13.4-13.9h0.1v0c0.4-0.4 1-0.4 1.4 0l1.4 1.4c0.4 0.4 0.4 1 0 1.4l0 0-16 16.6c-0.2 0.2-0.4 0.3-0.7 0.3-0.3 0-0.5-0.1-0.7-0.3l-7.8-8.4-0.2-0.3z'/%3E%3C/svg%3E");
        }
    }
}





.loader{
    --icon-size:22px;
    position: relative;
    display: table;
    width: 100%;
    height: 100%;
    max-width: 80px;

    .loader__item {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        width: calc(100% / 3);
        text-align: center;

        &:first-child .loader__item-icon {
            -webkit-animation-name: loaderBubble1Motion;
            animation-name: loaderBubble1Motion
        }
        &:nth-child(2) .loader__item-icon {
            -webkit-animation-name: loaderBubble2Motion;
            animation-name: loaderBubble2Motion
        }
        &:nth-child(3) .loader__item-icon {
            -webkit-animation-name: loaderBubble3Motion;
            animation-name: loaderBubble3Motion
        }

        .loader__item-icon {
            max-width: 100%;
            width: 50%;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            border-radius: 50%;
            background: var(--color-main);
            height: var(--icon-size);
            /* height: 25%; */
            width: var(--icon-size);
            display: block;
        }
    }
}

.breadcrumb {
    text-align: left;
    *, a {
        font-size: 14px;
        color: #212121;
    }
    a{
        color: var(--color-main)!important;
        &:hover{
            color: var(--color-main)!important;
            --color: var(--color-main)!important;
            text-decoration: underline;
           span{
            color: var(--color-main)!important;
            --color: var(--color-main)!important;
           }
        }
    }
    span {
        text-decoration: none;

    }
    .breadcrumb-arrow{
        margin: 0px 3px;
        display: inline-block;
    }
    .breadcrumb-item {
        display: inline-block;
        &:last-child{
            [itemprop="name"]{
                color: var(--color-main);
            }
        }
    }
}



@-webkit-keyframes loaderBubble1Motion {
    0% {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }

    30% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    60% {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }

    to {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }
}

@keyframes loaderBubble1Motion {
    0% {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }

    30% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    60% {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }

    to {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }
}

@-webkit-keyframes loaderBubble2Motion {
    0% {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }

    15% {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    75% {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }

    to {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }
}

@keyframes loaderBubble2Motion {
    0% {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }

    15% {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    75% {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }

    to {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }
}

@-webkit-keyframes loaderBubble3Motion {
    0% {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }

    30% {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }

    60% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    90% {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }

    to {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }
}

@keyframes loaderBubble3Motion {
    0% {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }

    30% {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }

    60% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    90% {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }

    to {
        -webkit-transform: scale(.2);
        transform: scale(.2)
    }
}
